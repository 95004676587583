import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';
import squadImg from "../assets/squad.png";
import profileImg from "../assets/profile.png";

const SquadRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const telegramId = urlParams.get('telegram_id');
  const usernameFromUrl = urlParams.get('username');
  const telegramNameFromUrl = urlParams.get('telegram_name');
  const profilePhotoUrlFromUrl = urlParams.get('profile_photo_url');

  const [profilePhotoUrl] = useState(profilePhotoUrlFromUrl);
  const [username, setUsername] = useState(usernameFromUrl);
  const [telegramName, setTelegramName] = useState(telegramNameFromUrl);
  const [squadMembers, setSquadMembers] = useState([]);
  const [squadName, setSquadName] = useState('');

  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate(`/?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}`);
    };

    const BackButton = window.Telegram.WebApp.BackButton;
    BackButton.show();
    BackButton.onClick(handleBackButtonClick);

    return () => {
      BackButton.hide();
      BackButton.offClick(handleBackButtonClick);
    };
  }, [navigate, telegramId, username, telegramName, profilePhotoUrl]);

  useEffect(() => {
    setUsername(usernameFromUrl);
    setTelegramName(telegramNameFromUrl);

    const fetchSquadData = async () => {
      try {
        const { data: userSquad, error: userSquadError } = await supabase
          .from('squad_member')
          .select('squad_id')
          .eq('user_id', telegramId);

        if (userSquadError) {
          throw userSquadError;
        }

        if (userSquad.length === 0) {
          console.warn('User is not a member of any squad.');
          return;
        }

        const squadId = userSquad[0].squad_id;

        const { data: squadData, error: squadError } = await supabase
          .from('squads')
          .select('squad_name')
          .eq('squad_id', squadId)
          .single();

        if (squadError) {
          throw squadError;
        }

        setSquadName(squadData.squad_name);

        const { data: squadMembersData, error: squadMembersError } = await supabase
          .from('squad_member')
          .select('user_id')
          .eq('squad_id', squadId);

        if (squadMembersError) {
          throw squadMembersError;
        }

        const userIds = squadMembersData.map(member => member.user_id);

        const { data: usersData, error: usersError } = await supabase
          .from('users')
          .select('telegram_id, coins, profile_photo_url, telegram_name, level_id')
          .in('telegram_id', userIds);

        if (usersError) {
          throw usersError;
        }

        // Fetch hex_color for each level_id
        const levelIds = usersData.map(user => user.level_id);
        const { data: levelsData, error: levelsError } = await supabase
          .from('levels')
          .select('level_id, hex_color')
          .in('level_id', levelIds);

        if (levelsError) {
          throw levelsError;
        }

        const combinedData = squadMembersData.map(member => {
          const userDetail = usersData.find(user => user.telegram_id === member.user_id);
          const levelDetail = levelsData.find(level => level.level_id === userDetail.level_id);
          return {
            user_id: member.user_id,
            coins: userDetail ? userDetail.coins : 0,
            profile_photo_url: userDetail ? userDetail.profile_photo_url : '',
            telegram_name: userDetail ? userDetail.telegram_name : '',
            hex_color: levelDetail ? levelDetail.hex_color : '#000000'
          };
        });

        // Sort the combined data by coins in descending order
        combinedData.sort((a, b) => b.coins - a.coins);

        setSquadMembers(combinedData);
      } catch (error) {
        console.error('Error fetching squad data:', error.message);
      }
    };

    fetchSquadData();
  }, [telegramId, usernameFromUrl, telegramNameFromUrl]);

  const handleLeaveSquad = async () => {
    try {
      const { data: userSquad, error: userSquadError } = await supabase
        .from('squad_member')
        .select('squad_id')
        .eq('user_id', telegramId);

      if (userSquadError) {
        throw userSquadError;
      }

      if (userSquad.length === 0) {
        console.warn('User is not a member of any squad.');
        return;
      }

      const squadId = userSquad[0].squad_id;

      const { error: deleteMemberError } = await supabase
        .from('squad_member')
        .delete()
        .eq('user_id', telegramId)
        .eq('squad_id', squadId);

      if (deleteMemberError) {
        throw deleteMemberError;
      }

      const { error: updateUserError } = await supabase
        .from('users')
        .update({ squad_id: null })
        .eq('telegram_id', telegramId);

      if (updateUserError) {
        throw updateUserError;
      }

      const { data: remainingMembers, error: remainingMembersError } = await supabase
        .from('squad_member')
        .select('user_id')
        .eq('squad_id', squadId);

      if (remainingMembersError) {
        throw remainingMembersError;
      }

      const { data: remainingUsersData, error: remainingUsersError } = await supabase
        .from('users')
        .select('coins')
        .in('telegram_id', remainingMembers.map(member => member.user_id));

      if (remainingUsersError) {
        throw remainingUsersError;
      }

      const newTotalCoins = remainingUsersData.reduce((sum, user) => sum + user.coins, 0);

      const { error: updateSquadError } = await supabase
        .from('squads')
        .update({
          squad_coins: newTotalCoins,
          squad_member: remainingMembers.length
        })
        .eq('squad_id', squadId);

      if (updateSquadError) {
        throw updateSquadError;
      }

      navigate(`/pages/squad?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}`);
    } catch (error) {
      console.error('Error leaving squad:', error.message);
    }
  };

  const calculateTotalCoins = () => {
    return squadMembers.reduce((total, member) => total + member.coins, 0);
  };

  return (
    <div className="bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-start font-mono">
      <FontAwesomeIcon
        className='p-2 bg-yellow-400 outline rounded-lg text-left absolute top-0 left-0 ml-5 mt-5 cursor-pointer'
        icon={faArrowLeft}
        onClick={() => navigate(`/?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}`)}
      />
      <div className="flex flex-col items-center justify-start pt-14 w-full px-4">
        <img src={squadImg} alt="Squad Logo" className="w-28 h-32 bg-slate-700 px-4 py-2 rounded-lg" />
        <div className="w-full max-w-screen-lg flex pt-10 flex-col items-center">
          <div className="flex justify-between w-full mb-2">
            <div className="py-1 px-2 bg-red-700 text-center w-1/5 rounded-md flex text-white font-bold border-4 border-red-500 flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-xs md:flex-none cursor-pointer"
              onClick={handleLeaveSquad}>
              Leave
            </div>
            <div className="py-1 px-2 bg-green-700 text-center w-1/5 rounded-md flex text-white font-bold border-4 border-green-500 flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-xs md:flex-none"
               onClick={() => navigate(`/pages/squad?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}`)}>
              Explore
            </div>
          </div>
          <div className="flex justify-between w-full mb-4 space-x-4">
            <div className="w-1/2 bg-slate-800 rounded-md p-4 flex flex-col text-yellow-400 items-center justify-center text-sm">
              <span>Total Member</span>
              <p className='text-white font-bold'>{squadMembers.length}</p>
            </div>
            <div className="w-1/2 bg-slate-800 rounded-md p-4 flex flex-col text-yellow-400 items-center justify-center text-sm">
              <span>Total Coins</span>
              <p className='text-white font-bold'>{calculateTotalCoins()}</p>
            </div>
          </div>

          <div className="bg-slate-800 w-full max-w-screen-lg rounded-md p-4 flex flex-col" style={{ maxHeight: '45vh' }}>
            <div className='text-center text-yellow-400 font-bold font-mono bg-slate-500 rounded-lg w-full py-2 mb-4 outline'>
              {squadName}
            </div>
            <div className="flex flex-col text-white text-xs overflow-y-auto">
              {squadMembers.map((member, index) => (
                <div key={index} className="flex items-center p-2 border-b border-slate-300">
                  <span className="text-white text-xs pr-4">{index + 1}.</span>
                  <img
                    src={member.profile_photo_url || profileImg}
                    alt="Profile"
                    className="w-10 h-10 rounded-full mr-4"
                    style={{ border: `3px solid ${(member.hex_color)}` }}
                  />
                  <div className="flex flex-col flex-grow">
                    <span className="text-sm text-nowrap font-bold">{member.telegram_name}</span>
                    <span className="text-xs text-yellow-400">🪙{member.coins}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquadRoom;
