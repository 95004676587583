import { formatUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";

export function shortenWalletAddress(address) {
    let addressShorten = address || '';
    if (addressShorten?.length < 10) return addressShorten; // Optional check to avoid very short addresses
    return `${addressShorten?.slice(0, 5)}...${addressShorten?.slice(-5)}`;
}

export function formatTokenAmount(amount, decimals = 18) {
    // Convert to BigNumber if not already
    const bigNumberAmount = BigNumber.from(amount);
    const formatted = formatUnits(bigNumberAmount, decimals);
    
    // Split into whole and decimal parts
    const [wholePart, decimalPart] = formatted.split('.');
    
    // Format whole part with commas
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    // Return formatted number with two decimal places
    return `${formattedWholePart}.${decimalPart ? decimalPart.slice(0, 2) : '00'}`;
}