import React, { useEffect, useContext } from 'react';
import Modal from 'react-modal';
import config from '../utils/config'
import GlobalContext from '../context/ContextProvider';
import { increaseCoinUser } from '../utils/api';

const RewardModal = ({ isOpen, setIsOpen, setIsExploding }) => {
    const { state, setCoinCount } = useContext(GlobalContext);

    const claimReward = async () => {
        try {
            const updatedCoins = Number(state?.CoinCount) + Number(config.REWARD_REFERRED);
            const { statusCode } = await increaseCoinUser(updatedCoins, state?.DataUser?.telegram_id)

            if (statusCode === 200) {
                setCoinCount(updatedCoins);
                setIsOpen(false);
                setIsExploding(false);
            }
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        setIsExploding(true);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            contentLabel="Profile Modal"
            className="modal font-mono"
            overlayClassName="modal-overlay"
        >
            <div className="relative">
                <div className='mb-5 max-w-fit mx-auto'>
                    <div className='rounded-full bg-yellow-500 border-[10px] border-yellow-700 p-5'>
                        <p className='text-6xl text-center text-white leading-none font-extrabold'>{config.REWARD_REFERRED}</p>
                    </div>
                </div>
                <p className='text-white text-center text-xl uppercase leading-none'>Congratulations!</p>
                <p className='text-white text-center'>Referral Bonus Earned</p>
                <button className="w-full mt-4 py-1 px-4 bg-yellow-500 text-white font-bold border-4 border-yellow-700 rounded-none transform hover:scale-105 active:scale-95 text-sm" onClick={() => {
                    claimReward();
                }}>Claim</button>
            </div>
        </Modal>
    )
}

export default RewardModal;