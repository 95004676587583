import React, { useState, createContext, useEffect } from 'react';
import supabase from '../supabaseClient';
import { getUserData } from '../utils/api';

const GlobalContext = createContext();

export const ContextProvider = ({ children }) => {
    const [IsDataReady, setIsDataReady] = useState(false);
    const [IsLoading, setIsLoading] = useState(false);
    const [DataUser, setDataUser] = useState(null);
    const [UserLevelColor, setUserLevelColor] = useState('#FFFFFF');
    const [UserActiveSkin, setUserActiveSkin] = useState(null);
    const [IsNotifOpen, setIsNotifOpen] = useState(false);
    const [ProfilePhoto, setProfilePhoto] = useState(null);
    const [CoinCount, setCoinCount] = useState(0);
    const [MaxEnergy, setMaxEnergy] = useState(0);
    const [Levels, setLevels] = useState([]);
    const [CurrentLevel, setCurrentLevel] = useState({});

    useEffect(() => {
        const fetchLevels = async () => {
            try {
                const { data: levelsData, error } = await supabase
                    .from('levels')
                    .select('*');

                if (error) {
                    throw new Error('Failed to fetch levels data');
                }

                setLevels(levelsData);
            } catch (error) {
                console.error('Error fetching levels data:', error.message);
            }
        };

        fetchLevels();
    }, []);

    useEffect(() => {
        if (!DataUser || Levels.length === 0) return;

        const updateUserLevel = async () => {
            // Sort levels by minimum_coins in ascending order
            const sortedLevels = [...Levels].sort((a, b) => a.minimum_coins - b.minimum_coins);

            // Find the highest level the user qualifies for
            const currentLevel = sortedLevels.reverse().find(level => CoinCount >= level.minimum_coins);

            if (currentLevel && currentLevel.level_id !== DataUser.level_id) {
                try {
                    // Update user's level_id in the database
                    const { data, error } = await supabase
                        .from('users')
                        .update({ level_id: currentLevel.level_id })
                        .eq('id', DataUser.id)
                        .single();

                    if (error) {
                        throw error;
                    }

                    const { data: users } = await getUserData(DataUser.telegram_id);
                    setDataUser(users);
                    setCoinCount(users.coins);
                    setMaxEnergy(users.max_energy);
                    setCurrentLevel(users.levels);
                    setUserLevelColor(users.levels.hex_color);
                    // console.log(`Updated user level to ${currentLevel.level_id} successfully`);
                } catch (error) {
                    console.error('Error updating user level:', error.message);
                }
            }
        };

        updateUserLevel();
    }, [CoinCount, DataUser, Levels]);

    useEffect(() => {
        if (IsDataReady) {
            const timer = setTimeout(() => {
                setIsLoading(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [IsDataReady]);

    return (
        <GlobalContext.Provider value={{
            state: {
                IsDataReady,
                IsLoading,
                DataUser,
                UserLevelColor,
                UserActiveSkin,
                IsNotifOpen,
                ProfilePhoto,
                CoinCount,
                MaxEnergy,
                CurrentLevel
            },
            setIsDataReady,
            setIsLoading,
            setDataUser,
            setUserLevelColor,
            setUserActiveSkin,
            setIsNotifOpen,
            setProfilePhoto,
            setMaxEnergy,
            setCoinCount,
            setCurrentLevel
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
