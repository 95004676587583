import React, { useEffect, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { WebAppProvider, BackButton } from '@vkruglikov/react-telegram-web-app';
import { Menu, Transition } from '@headlessui/react';
import supabase from '../supabaseClient';

const Leaderboard = () => {
  const [topPlayers, setTopPlayers] = useState([]);
  const [topSquads, setTopSquads] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Farmer');

  useEffect(() => {
    fetchTopPlayers();
    fetchTopSquads();
    fetchLevels();
  }, []);

  const fetchTopPlayers = async () => {
    try {
      const { data: players, error } = await supabase
        .from('users')
        .select('id, username, telegram_name, coins, profile_photo_url, level_id')
        .order('coins', { ascending: false })
        .limit(100);

      if (error) {
        throw error;
      }

      setTopPlayers(players);
    } catch (error) {
      console.error('Error fetching top players:', error.message);
    }
  };

  const fetchTopSquads = async () => {
    try {
      const { data: squads, error } = await supabase
        .from('squads')
        .select('squad_id, squad_name, squad_coins')
        .order('squad_coins', { ascending: false })
        .limit(10);

      if (error) {
        throw error;
      }

      setTopSquads(squads);
    } catch (error) {
      console.error('Error fetching top squads:', error.message);
    }
  };

  const fetchLevels = async () => {
    try {
      const { data: levels, error } = await supabase
        .from('levels')
        .select('level_id, hex_color, name_level');

      if (error) {
        throw error;
      }

      setLevels(levels);
    } catch (error) {
      console.error('Error fetching levels:', error.message);
    }
  };

  const getLevelInfo = (levelId) => {
    return levels.find(level => level.level_id === levelId) || {};
  };

  const handleLevelSelect = (levelId) => {
    setSelectedLevel(levelId);
  };

  const handleClear = () => {
    setSelectedLevel(null);
  };

  const filteredPlayers = selectedLevel
    ? topPlayers.filter(player => player.level_id === selectedLevel)
    : topPlayers;

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-start">
      <BackButton onClick={() => window.history.back()} />
      <FontAwesomeIcon
        className='p-2 bg-yellow-400 outline rounded-lg text-left absolute top-0 left-0 ml-5 mt-5 cursor-pointer'
        icon={faArrowLeft}
        onClick={() => window.history.back()}
      />

      <div className="flex flex-col items-center justify-start pt-14 w-full px-4">
        <img src="../leaderboard.png" alt="Coin Logo" className="w-32 h-32 my-8" />

        <div className="w-full max-w-screen-lg flex flex-col items-center">
          {/* Dropdown Menus Container */}
          <div className="flex justify-between w-full mb-4">
            {/* Left Dropdown Menu */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-slate-300 shadow-sm px-4 py-2 bg-slate-800 text-sm font-medium text-white hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  {selectedCategory}
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.707 7.293a1 1 0 011.414 0L10 10.172l2.879-2.879a1 1 0 011.414 1.414l-3.586 3.586a1 1 0 01-1.414 0L5.707 8.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-24 rounded-md shadow-lg bg-slate-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleCategorySelect('Squad')}
                          className={`${
                            active ? 'bg-slate-700 text-yellow-400' : 'text-yellow-400'
                          } group flex items-center w-full px-2 py-2 text-sm border-b border-gray-700`}
                        >
                          Squad
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleCategorySelect('Farmer')}
                          className={`${
                            active ? 'bg-slate-700 text-yellow-400' : 'text-yellow-400'
                          } group flex items-center w-full px-2 py-2 text-sm`}
                        >
                          Farmer
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            {/* Right Dropdown Menu */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-slate-300 shadow-sm px-4 py-2 bg-slate-800 text-sm font-medium text-white hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                  Level
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.707 7.293a1 1 0 011.414 0L10 10.172l2.879-2.879a1 1 0 011.414 1.414l-3.586 3.586a1 1 0 01-1.414 0L5.707 8.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-24 border border-slate-700 rounded-md shadow-lg bg-slate-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleClear()}
                          className={`${
                            active ? 'bg-slate-700 text-white' : 'text-white'
                          } group flex items-center w-full px-2 py-2 text-sm border-b border-gray-700`}
                        >
                          All
                        </button>
                      )}
                    </Menu.Item>
                    {levels.map(level => (
                      <Menu.Item key={level.level_id}>
                        {({ active }) => (
                          <button
                            onClick={() => handleLevelSelect(level.level_id)}
                            className={`${
                              active ? 'bg-slate-700 text-yellow-400' : 'text-yellow-500'
                            } group flex items-center w-full px-2 py-2 text-sm border-b border-gray-700`}
                          >
                            {level.name_level}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

        {/* Leaderboard List */}
        <div className="bg-teal-800 w-full rounded-md mb-4 p-4 flex flex-col" style={{ maxHeight: '45vh' }}>
                <div className='text-center text-yellow-400 font-bold font-mono bg-teal-900 rounded-lg w-full py-2 mb-4 outline'>
                  Leaderboard - {selectedCategory}
                </div>
                <div className="flex flex-col text-white font-mono overflow-y-auto">
                  {selectedCategory === 'Farmer' && filteredPlayers.length > 0 ? (
                    filteredPlayers.map((player, index) => (
                      <div key={player.id} className="flex items-center p-2 border-b border-gray-400">
                        {/* Profile Photo */}
                        <span className="text-white text-xs pr-4">{index + 1}.</span>
                        <img
                          src={player.profile_photo_url || '../profile.png'}
                          alt="Profile"
                          className="w-10 h-10 rounded-full mr-4"
                          style={{ border: `3px solid ${getLevelInfo(player.level_id).hex_color}` }}
                        />
                        <div className="flex flex-col flex-grow">
                          {/* Telegram Name */}
                          <span className="text-sm text-nowrap font-bold">{player.telegram_name}</span>
                          {/* Coins */}
                          <span className="text-xs text-yellow-400">🪙{player.coins}</span>
                        </div>
                        {/* Level */}
                        <div className={`text-xs border-${getLevelInfo(player.level_id).hex_color}-500 mt-5 pl-2 my-1`}>
                          {getLevelInfo(player.level_id).name_level}
                        </div>
                      </div>
                    ))
                  ) : selectedCategory === 'Squad' && topSquads.length > 0 ? (
                    topSquads.map((squad, index) => (
                      <div key={squad.id} className="flex items-center p-2 border-b border-gray-400">
                        <span className="text-white text-xs pr-4">{index + 1}.</span>
                        <div className="flex flex-col flex-grow">
                          {/* Squad Name */}
                          <span className="text-sm text-nowrap font-bold">{squad.squad_name}</span>
                          {/* Squad Coins */}
                          <span className="text-xs text-yellow-400">🪙{squad.squad_coins}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-white font-mono mt-2">...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

const App = () => {
  return (
    <WebAppProvider options={{ smoothButtonsTransition: true }}>
      <Leaderboard />
    </WebAppProvider>
  );
};

export default App;