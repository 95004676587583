import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons';
import { WebAppProvider, BackButton } from '@vkruglikov/react-telegram-web-app';
import supabase from '../supabaseClient';
import Modal from 'react-modal';
import friendImg from '../assets/friend.png'
import addfriendImg from '../assets/addfriend.png'
import { getReferralRewards, getUserReferralRewardsById } from '../utils/api';
import GlobalContext from '../context/ContextProvider';
import Notification from '../component/Notification';

const Friends = () => {
  const [isModalReffOpen, setisModalReffOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const { state, setIsNotifOpen, setCoinCount } = useContext(GlobalContext);
  // const urlParams = new URLSearchParams(window.location.search);
  // const telegramId = urlParams.get('telegram_id');
  // const referrerId = urlParams.get('referrer');

  const fetchTasks = async () => {
    try {
      const { data: referralRewards } = await getReferralRewards();
      const { data: userReferralRewards } = await getUserReferralRewardsById(state?.DataUser?.telegram_id);

      if (referralRewards && userReferralRewards) {
        const mergedTasks = referralRewards.map((reward) => {
          const userReward = userReferralRewards.find((ur) => ur.reward_id === reward.referral_id);
          return {
            ...reward,
            is_claimed: userReward ? userReward.is_claimed : null,
          };
        });

        mergedTasks.sort((a, b) => a.id - b.id);

        setTasks(mergedTasks);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const claimReward = async (rewardId, rewardCoins) => {
    try {
      const { error: updateError } = await supabase
        .from('user_referral_rewards')
        .update({ is_claimed: true })
        .eq('user_id', state?.DataUser?.telegram_id)
        .eq('reward_id', rewardId);

      if (updateError) throw updateError;

      const updatedCoins = Number(state?.CoinCount) + Number(rewardCoins);
      const { error: coinsError } = await supabase
        .from('users')
        .update({ coins: updatedCoins })
        .eq('telegram_id', state?.DataUser?.telegram_id);

      if (coinsError) throw coinsError;

      setCoinCount(updatedCoins);
      setTasks(tasks.map(task =>
        task.referral_id === rewardId ? { ...task, is_claimed: true } : task
      ));
      setIsNotifOpen(true);
    } catch (error) {
      console.error('Error claiming reward:', error);
    }
  };

  useEffect(() => {
    if (state?.DataUser) {
      fetchTasks();
    }
  }, []);

  const copyReferralLink = async () => {
    try {
      await navigator.clipboard.writeText(`https://t.me/spunfarmer_ref_bot?start=spunId${state?.DataUser?.telegram_id}`);
      setIsCopied(true);
    } catch (error) {
      console.error('Error copying referral link:', error);
    }
  };

  return (
    <div className="relative bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-start font-mono">
      <Notification
        warningType={'success'}
        message={'Reward successfully claimed!'}
        IsOpen={state?.IsNotifOpen}
        setIsOpen={setIsNotifOpen}
      />
      <BackButton onClick={() => window.history.back()} />
      <FontAwesomeIcon className='p-2 bg-yellow-400 outline rounded-lg text-left absolute top-0 left-0 ml-4 mt-5 cursor-pointer' icon={faArrowLeft} onClick={() => window.history.back()} />
      <div className="flex flex-col items-center justify-center pt-20 w-full px-4">
        <img src={friendImg} alt="Friend Logo" className="w-34 h-32" />
        <div className='w-full mt-8'>
          <h1 className="text-lg font-bold font-sans">Invite your friend:</h1>
          <button onClick={() => setisModalReffOpen(true)} className='bg-blue-500 font-bold text-md text-left w-full px-4 border-4 border-blue-700 rounded-md py-2 mt-1 flex items-center justify-between text-white'>
            Invite Friends
            <FontAwesomeIcon icon={faCopy} className="ml-2 cursor-pointer" />
          </button>
        </div>
        {/* <div className='w-full mt-2'>
          <p className='text-start'>Your referrer: {ReferrerId ? ReferrerId : '-'}</p>
        </div> */}
        <div className='w-full mt-8 flex flex-col mb-3 items-start'>
          <h1 className="text-lg font-bold font-sans mb-2">Rewards:</h1>
          <div className='w-full bg-teal-800 rounded-md shadow-md p-4 max-h-[calc(100vh-400px)] overflow-y-auto'>
            {tasks.map(task => (
              <div key={task.reward_id} className='p-2 mb-2 border-b border-slate-100 w-full flex justify-between items-center'>
                <div className='flex items-center'>
                  <img src={addfriendImg} alt='friendimg' className='w-8 h-7 mr-2'></img>
                  <div>
                    <h2 className='font-bold text-white text-md'>
                      Invite {task.invite_count} Friend
                    </h2>
                    <p className='text-sm text-yellow-400'>🪙{task.reward_coins}+</p>
                  </div>
                </div>
                {task.is_claimed === null ? null :
                  task.is_claimed ?
                    <button className='font-bold rounded-sm py-1 px-2'>
                      <svg className='w-5 h-5 text-yellow-400' fill='currentColor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    </button>
                    :
                    <button onClick={() => claimReward(task.referral_id, task.reward_coins)} className='bg-green-500 text-white font-bold border-4 border-green-700 rounded-sm text-sm py-1 px-2'>
                      🎁
                    </button>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalReffOpen}
        onRequestClose={() => setisModalReffOpen(false)}
        contentLabel="Wallet Modal"
        className="modal font-mono "
        overlayClassName="modal-overlay">
        <div className="modal-content">
          <div className="flex items-center">
          </div>
          <h1 className='text-yellow-400 font-bold rounded-lg py-2 mb-4 outline text-center'>Share Invite Link </h1>
          <p className="text-white text-xs text-center mt-4">https://t.me/spunfarmer_ref_bot?start=spunId{state?.DataUser?.telegram_id}</p>
          <button
            onClick={copyReferralLink}
            className="mt-10 bg-blue-500 font-bold text-md px-2 border-4 border-blue-700 rounded-md py-1 text-white">
            {isCopied ? 'Copied' : 'Copy'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

const App = () => {
  return (
    <WebAppProvider options={{ smoothButtonsTransition: true }}>
      <Friends />
    </WebAppProvider>
  );
};

export default App;
