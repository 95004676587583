import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { WebAppProvider, BackButton } from '@vkruglikov/react-telegram-web-app';
import earnImg from "../assets/earn.png";
import supabase from '../supabaseClient';
import Modal from 'react-modal';
import { shortenWalletAddress } from '../utils/globalUtils';
import Notification from '../component/Notification';
import GlobalContext from '../context/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../utils/api';

window.open = (function (open) {
  return function (url, _, features) {
    return open.call(window, url, "_blank", features);
  };
})(window.open);

const Earn = () => {
  const navigate = useNavigate();
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [WalletAddress, setWalletAddress] = useState();
  const { state, setIsNotifOpen, setCoinCount, setDataUser } = useContext(GlobalContext);
  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get('telegram_id');

  const addWalletAddress = async (walletAddress) => {

    try {
      const { error } = await supabase
        .from('users')
        .update({ wallet_address: walletAddress })
        .eq('telegram_id', telegramId)
        .select()

      if (error) {
        console.error(error);
      }

      const { statusCode, data: userData } = await getUserData(telegramId);

      if (statusCode === 200) {
        setDataUser(userData);
      }

      setIsWalletModalOpen(false);
    } catch (error) {
      setIsWalletModalOpen(false);
      console.error('Error:', error.message);
    }

  };

  // Fetch tasks
  const fetchTasks = useCallback(async () => {
    try {
      const { data, error } = await supabase.from('tasks').select('*');
      if (error) {
        throw error;
      }
      setTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error.message);
    }
  }, []);

  // Fetch completed tasks for the user
  const fetchCompletedTasks = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('user_tasks')
        .select('task_id')
        .eq('user_id', telegramId)
        .eq('is_completed', true);
      if (error) {
        throw error;
      }
      setCompletedTasks(data.map(item => item.task_id));
    } catch (error) {
      console.error('Error fetching completed tasks:', error.message);
    }
  }, [telegramId]);

  useEffect(() => {
    fetchTasks();
    fetchCompletedTasks();
  }, [fetchTasks, fetchCompletedTasks]);

  const handleTaskClick = async (task) => {
    const { task_id, task_link, task_coins } = task;

    // Check if the task is already completed
    if (completedTasks.includes(task_id)) {
      // Open the task link in a new tab
      window.open(task_link, '_blank');
      return; // No action needed if task is completed
    }

    try {
      // Save the task as completed (only update local state, not the database)
      setCompletedTasks([...completedTasks, task_id]);

      // Open the task link in a new tab
      window.open(task_link, '_blank');

      // Insert data into user_tasks table
      const { error: insertError } = await supabase
        .from('user_tasks')
        .insert([{
          user_id: telegramId,
          task_id: task_id,
          is_completed: true,
          completed_at: new Date(),
        }]);
      if (insertError) {
        throw insertError;
      }

      // Fetch the user's current coin count
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('coins')
        .eq('telegram_id', telegramId)
        .single();
      if (userError) {
        throw userError;
      }

      // Calculate the new coin count after completing the task
      const newCoinCount = userData.coins + task_coins;

      // Update the user's coin count in the database
      const { error: updateError } = await supabase
        .from('users')
        .update({ coins: newCoinCount })
        .eq('telegram_id', telegramId);
      if (updateError) {
        throw updateError;
      }

      setCoinCount(newCoinCount);
      // Update the local state with the new coin count
    } catch (error) {
      console.error('Error handling task click:', error.message);
    }
  };

  return (
    <div className="relative bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-start font-mono">
      <Notification
        warningType={'alert'}
        message={'Please connect your wallet first!'}
        IsOpen={state?.IsNotifOpen}
        setIsOpen={setIsNotifOpen}
      />
      <BackButton onClick={() => window.history.back()} />
      <FontAwesomeIcon
        className='p-2 bg-yellow-400 outline rounded-lg text-left absolute top-0 left-0 ml-4 mt-5 cursor-pointer'
        icon={faArrowLeft}
        onClick={() => window.history.back()}
      />
      <div className="flex flex-col items-center justify-center pt-10 w-full px-4">
        {/* <h1 className="text-7xl font-bold mb-4">💸</h1> */}
        <div className="flex flex-col items-center justify-center">
          <img src={earnImg} alt="Squad Logo" className="w-36 h-36" />
        </div>
        <div className='w-full mt-10'>
          <h1 className="text-lg font-bold font-sans">Wallet:</h1>
          {state?.DataUser?.wallet_address ?
            <div className='flex space-x-2 items-center'>
              <p className='font-bold text-lg'>{shortenWalletAddress(state?.DataUser?.wallet_address)}</p>
              <button
                className='font-bold text-md bg-yellow-400 rounded-md p-2'
                onClick={() => {
                  setIsWalletModalOpen(true);
                  setWalletAddress(state?.DataUser?.wallet_address);
                  }}>
                change
              </button>
            </div>
            :
            <button
              className='font-bold text-md outline-dashed bg-yellow-400 rounded-md py-2 w-full mt-1'
              onClick={() => setIsWalletModalOpen(true)}>
              Add your wallet
            </button>
          }
        </div>

        <div className='w-full mt-10'>
          <h1 className="text-lg font-bold font-sans">Invite your friend:</h1>
          <button onClick={() => { navigate(`/pages/friends?telegram_id=${telegramId}`) }} className='font-bold bg-blue-700 text-center rounded-md p-2 py-2 px-5 flex text-white border-4 border-blue-400 items-center justify-center transform  active:scale-95 md:flex-none w-full mt-1'>
            <img src='../friend.png' className='w-7 h-7 mr-2' alt="Friend"></img>
            Invite +
          </button>
        </div>

        <div className='w-full mt-8 mb-3 flex flex-col items-start'>
          <h1 className="text-lg font-bold font-sans mb-2">Task:</h1>
          <div className='w-full bg-teal-800 rounded-md shadow-md p-4 max-h-[calc(100vh-400px)] overflow-y-auto'>
            {tasks.map(task => (
              <div
                key={task.task_id}
                className={`flex items-center justify-between p-2 mb-2 bg-teal-900 w-full no-underline rounded-md cursor-pointer ${completedTasks.includes(task.task_id) ? 'bg-gray-900' : 'hover:bg-teal-700'}`}
                onClick={() => handleTaskClick(task)}>
                <div className='flex items-center'>
                  <img src={task.task_img} alt="task-img" className='w-10 h-10 mr-4 rounded-md' />
                  <div>
                    <h2 className='font-bold text-white text-md'>{task.task_name}</h2>
                    <p className='text-sm text-yellow-400'>+{task.task_coins} Coins</p>
                  </div>
                </div>
                {/* Completed task icon */}
                {completedTasks.includes(task.task_id) ? (
                  <FontAwesomeIcon icon={faCheck} className="text-green-500 pr-2" /> // Icon ceklis jika task sudah selesai
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} className="text-yellow-400 pr-2" /> // Icon arrow right jika task belum selesai
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isWalletModalOpen}
        onRequestClose={() => setIsWalletModalOpen(false)}
        contentLabel="Wallet Modal"
        className="modal font-mono"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <div className='flex mt-1 space-x-2'>
            <input className='w-full border border-4 p-2 text-yellow-400 outline-none border-yellow-400 bg-transparent rounded-md' placeholder='Ox...' value={WalletAddress} onChange={(e) => { setWalletAddress(e.target.value); }} />
            <button
              className='font-bold text-md bg-yellow-400 rounded-md p-2'
              onClick={() => { addWalletAddress(WalletAddress); }}>
              Add
            </button>
          </div>
          <button
            onClick={() => setIsWalletModalOpen(false)}
            className="mt-6 bg-red-500 text-white py-1 text-sm rounded-md"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

const App = () => {
  return (
    <WebAppProvider options={{ smoothButtonsTransition: true }}>
      <Earn />
    </WebAppProvider>
  );
};

export default App;