import supabase from "../supabaseClient";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

export async function uploadImageFromUrl(imageUrl, telegramId) {
    try {
        // Fetch the image from the URL
        const response = await fetch(`/file/bot${process.env.REACT_APP_BOT_ID}/photos/file_27.jpg`, {
            method: 'GET', // Ensure you're using the correct method
            headers: {
                'Content-Type': 'image/jpeg',
            },
        });
        if (!response.ok) throw new Error('Failed to fetch image');

        // Convert the image to a Blob
        const imageBlob = await response.blob();

        // Resize the image using a canvas
        const resizedBlob = await resizeImage(imageBlob, 200, 200);

        // Upload the resized image to Supabase storage
        const fileName = `profile_photos/${telegramId}.jpg`; // Customize the path and file name as needed
        const { data, error } = await supabase.storage
            .from('user_profile') // Replace with your Supabase storage bucket name
            .upload(fileName, resizedBlob, {
                contentType: response.headers.get('content-type'),
            });

        if (error) throw error;

        // Construct the new image URL
        const newImageUrl = `${supabaseUrl}/storage/v1/object/public/user_profile/${fileName}`;

        return newImageUrl;
    } catch (error) {
        console.error('Error uploading image:', error.message);
        return null;
    }
}

function resizeImage(imageBlob, width, height) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageBlob);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        };
        img.onerror = (err) => {
            reject(err);
        };
    });
}

export async function getUserRewardsByLvId(lvId) {
    try {
        const { data, error } = await supabase
            .from('user_rewards')
            .select('*, levels(*)')
            .eq('level_id', lvId)

        if (error) throw error;

        return data[0];
    } catch (error) {
        console.error('Error fetching user rewards with level:', error.message);
        return null;
    }
}

export async function getUserRewardsByUserId(user_id, user_level_id, is_claimed = false) {
    try {
        let { data: user_rewards, error } = await supabase
            .from('user_rewards')
            .select('*, levels(*)')
            .eq('is_claimed', is_claimed)
            .eq('user_id', user_id);

        if (error) throw error;

        const filtered_rewards = user_rewards.filter(reward => reward.level_id !== 1 && reward.level_id <= user_level_id);

        return filtered_rewards;
    } catch (error) {
        console.error('Error fetching user rewards with user id:', error.message);
        return null;
    }
}

export async function createUserRewards(telegramId) {
    try {
        const { data: levels, error: levelsError } = await supabase
            .from('levels')
            .select('level_id');

        if (levelsError) throw levelsError;

        const userRewards = levels.map(level => ({
            user_id: telegramId,
            level_id: level.level_id,
            is_claimed: false,
            claimed_at: null
        }));

        const { data, error: insertError } = await supabase
            .from('user_rewards')
            .insert(userRewards);

        if (insertError) throw insertError;

        return { statusCode: 200, message: 'User rewards created successfully' };
    } catch (error) {
        console.error('Error creating user rewards:', error.message);
    }
};

export async function getUserData(telegram_id) {
    try {
        const { data: user, error: userError } = await supabase
            .from('users')
            .select('*, levels(*)')
            .eq('telegram_id', telegram_id);

        if (userError) throw userError;

        return { statusCode: 200, message: 'User data was successfully retrieved', data: user[0] };
    } catch (error) {
        console.error('Error fetching user data:', error.message);
        return null;
    }
}

export async function getReferralRewards() {
    try {
        let { data: referral_rewards, error } = await supabase
            .from('referral_rewards')
            .select('*')

        if (error) throw error;

        return { statusCode: 200, message: 'Referral rewards data was successfully retrieved', data: referral_rewards };
    } catch (error) {
        console.error('Error fetching referral rewards data:', error.message);
    }
}

export async function getUserReferralRewardsById(telegram_id) {
    try {
        let { data: user_referral_rewards, error } = await supabase
            .from('user_referral_rewards')
            .select('*')
            .eq('user_id', Number(telegram_id));

        if (error) throw error;

        return { statusCode: 200, message: 'User referral rewards data was successfully retrieved', data: user_referral_rewards };
    } catch (error) {
        console.error('Error fetching user referral rewards data:', error.message);
    }
}

export async function increaseCoinUser(coin, telegram_id) {
    try {
        const { error: coinsError } = await supabase
            .from('users')
            .update({ coins: coin })
            .eq('telegram_id', telegram_id);

        if (coinsError) throw coinsError;

        return { statusCode: 200, message: 'Coin successfully increase!' };
    } catch (error) {
        console.error(`Error increasing coin user with id ${telegram_id}: `, error.message);
    }
}

export async function getReferralByUserId(telegram_id) {
    try {

        let { data: referrals, error } = await supabase
            .from('referrals')
            .select('*')
            .eq('referrer_id', Number(telegram_id));

        if (error) throw error;

        return { statusCode: 200, message: 'Referral data was successfully retrieved!', data: referrals };
    } catch (error) {
        console.error(`Error fetching referral data: `, error.message);
    }
}

export async function setReferralByUserId(telegram_id, referrer_id) {
    try {
        const { data, error } = await supabase
            .from('referrals')
            .insert([
                {
                    referrer_id: Number(referrer_id),
                    referred_id: Number(telegram_id),
                    referred_at: new Date().toISOString()
                },
            ])
            .select()
        if (error) throw error;

        return { statusCode: 200, message: 'Referrer successfully added!', data: data };
    } catch (error) {
        console.error(`Error adding new referrer: `, error.message);
    }
}