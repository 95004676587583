import { useEffect } from "react";

const Notification = ({ warningType, message, IsOpen, setIsOpen }) => {

    useEffect(() => {
        if (IsOpen) {
            const timer = setTimeout(() => {
                setIsOpen(false);
            }, 3000);
            return () => clearTimeout(timer); // Cleanup the timer on component unmount or when IsOpen changes
        }
    }, [IsOpen, setIsOpen]);

    if (!IsOpen) return null;

    return (
        <div className="z-50 absolute top-0 right-0 m-5">
            <div id="toast-warning" class={`flex items-center w-full max-w-xs px-3 py-2 text-gray-500 bg-white rounded-lg shadow ${warningType === "success" ? "border border-green-500" : "border border-orange-500"}`} role="alert">
                <div class={`ms-3 text-sm ${warningType === "success" ? "text-green-500" : "text-orange-500"} font-normal`}>{message}</div>
                <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-warning" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Notification;