import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUp, faChevronLeft, faChevronRight, faDroplet } from '@fortawesome/free-solid-svg-icons';
import { WebAppProvider, BackButton } from '@vkruglikov/react-telegram-web-app';
import supabase from '../supabaseClient';
import { createThirdwebClient, getContract, defineChain, prepareContractCall } from "thirdweb";
import ABI from '../assets/ABI.json';
import { ethers } from 'ethers';
import GlobalContext from '../context/ContextProvider';
import { useNavigate } from 'react-router-dom';

const Store = () => {
  const navigate = useNavigate();
  const [skins, setSkins] = useState([]);
  const [purchasedSkins, setPurchasedSkins] = useState([]);
  const [energyLevels, setEnergyLevels] = useState([]);
  const [userMaxEnergy, setUserMaxEnergy] = useState(0);
  const [upgradedLevels, setUpgradedLevels] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const telegramId = urlParams.get('telegram_id');
  const { state, setIsNotifOpen } = useContext(GlobalContext);
  const client = createThirdwebClient({ clientId: process.env.REACT_APP_TW_ID });
  const flareChain = defineChain({
    id: 14,
    rpc: "https://flare-api.flare.network/ext/C/rpc",
    nativeCurrency: {
      name: "Flare Mainnet",
      symbol: "FLR",
      decimals: 18,
    },
  });
  const contract = getContract({
    client,
    chain: flareChain,
    address: "0xB8b206037E6d0b9EbDc5e75cDB4e84fED1dD1E20",
    abi: ABI,
  });

  useEffect(() => {
    async function fetchSkins() {
      try {
        const { data, error } = await supabase.from('skins').select('*');
        if (error) {
          throw error;
        }
        setSkins(data);
      } catch (error) {
        console.error('Error fetching skins:', error.message);
      }
    }

    async function fetchPurchasedSkins() {
      try {
        const { data, error } = await supabase
          .from('user_skins')
          .select('skin_id')
          .eq('user_id', telegramId);
        if (error) {
          throw error;
        }
        const purchasedSkinIds = data.map((item) => item.skin_id);
        setPurchasedSkins(purchasedSkinIds);
      } catch (error) {
        console.error('Error fetching purchased skins:', error.message);
      }
    }

    async function fetchEnergyLevels() {
      try {
        const { data, error } = await supabase.from('extra_energy_levels').select('*').order('level');
        if (error) {
          throw error;
        }
        setEnergyLevels(data);
      } catch (error) {
        console.error('Error fetching energy levels:', error.message);
      }
    }

    async function fetchUserMaxEnergy() {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('max_energy')
          .eq('telegram_id', telegramId)
          .single();
        if (error) {
          throw error;
        }
        setUserMaxEnergy(data.max_energy);
      } catch (error) {
        console.error('Error fetching user max energy:', error.message);
      }
    }

    async function fetchUpgradedLevels() {
      try {
        const { data, error } = await supabase
          .from('user_extra_energy')
          .select('extra_energy_id')
          .eq('user_id', telegramId);
        if (error) {
          throw error;
        }
        const upgradedIds = data.map((item) => item.extra_energy_id);
        setUpgradedLevels(upgradedIds);
      } catch (error) {
        console.error('Error fetching upgraded levels:', error.message);
      }
    }

    fetchSkins();
    fetchPurchasedSkins();
    fetchEnergyLevels();
    fetchUserMaxEnergy();
    fetchUpgradedLevels();
  }, [telegramId]);

  const handleBuySkin = async (skinId) => {
    try {
      const purchasedAt = new Date().toISOString();

      // Save a new entry in the "user_skins" table
      const { error } = await supabase.from('user_skins').insert([{ user_id: telegramId, skin_id: skinId, purchased_at: purchasedAt }]);
      if (error) {
        throw error;
      }

      // Update the purchased skins state
      setPurchasedSkins([...purchasedSkins, skinId]);

    } catch (error) {
      console.error('Error buying skin:', error.message);
    }
  };

  const handleUpgradeEnergy = async (level) => {
    try {
      const { id, energy_bonus } = level;
      const newMaxEnergy = userMaxEnergy + energy_bonus;

      // Ambil extra_energy saat ini dari tabel users
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('extra_energy')
        .eq('telegram_id', telegramId)
        .single();

      if (userError) {
        throw userError;
      }

      const currentExtraEnergy = userData.extra_energy || 0; // Jika null, set ke 0
      const newExtraEnergy = currentExtraEnergy + energy_bonus;

      // Insert data ke dalam user_extra_energy table
      const { error: insertError } = await supabase.from('user_extra_energy').insert([{ user_id: telegramId, extra_energy_id: id }]);
      if (insertError) {
        throw insertError;
      }

      // Update max_energy dan extra_energy di tabel users
      const { error: updateError } = await supabase
        .from('users')
        .update({ max_energy: newMaxEnergy, extra_energy: newExtraEnergy })
        .eq('telegram_id', telegramId);
      if (updateError) {
        throw updateError;
      }

      // Update state
      setUserMaxEnergy(newMaxEnergy);
      setUpgradedLevels([...upgradedLevels, id]);
    } catch (error) {
      console.error('Error upgrading energy:', error.message);
    }
  };


  const isSkinPurchased = (skinId) => {
    return purchasedSkins.includes(skinId);
  };

  const isEnergyUpgraded = (levelId) => {
    return upgradedLevels.includes(levelId);
  };

  // Mendapatkan tingkat energi berikutnya yang belum diupgrade
  const getNextEnergyLevel = () => {
    for (let i = 0; i < energyLevels.length; i++) {
      if (!upgradedLevels.includes(energyLevels[i].id)) {
        return energyLevels[i];
      }
    }
    return null; // Return null jika semua tingkat energi sudah diupgrade
  };

  const nextEnergyLevel = getNextEnergyLevel();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} className="slick-prev" />,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} className="slick-next" />,
    centerMode: true,
    centerPadding: '25px',
  };

  const buySkin = async () => {
    if (!state?.DataUser.wallet_address) {
      setIsNotifOpen(true);
      navigate(`/pages/earn?telegram_id=${telegramId}`)
      return;
    }
    let address = ethers.utils.getAddress();
    let amount = 0;

    const transaction = await prepareContractCall({
      contract,
      method: "function transfer(address to, uint256 value)",
      params: [address, amount],
    });

    console.log(transaction);
  };

  return (
    <div className="bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-start font-mono">
      <BackButton onClick={() => window.history.back()} />
      <FontAwesomeIcon
        className="p-2 bg-yellow-400 outline rounded-lg text-left absolute top-0 left-0 ml-4 mt-5 cursor-pointer"
        icon={faArrowLeft}
        onClick={() => window.history.back()}
      />
      {/* Container $SPUN */}
      <div className="mt-20">
        <h3 className="text-base font-bold">Balance</h3>
        <h1 className="text-3xl">
          1,234,151 <span className="text-lg">$SPUN</span>
        </h1>
      </div>

      {/* Button Upgrade Energy Start */}
      <div className="w-full mt-8 px-4">
        <h1 className="text-md font-bold font-sans">Energy:</h1>
        {nextEnergyLevel ? (
          <div key={nextEnergyLevel.id} className="flex items-center bg-yellow-400 rounded-md py-3 px-4 w-full mt-1">
            <FontAwesomeIcon
              icon={faDroplet}
              className="w-10 h-10 text-blue-500 bg-yellow-600 px-2 py-2 rounded-md"
            />
            <div className="ml-4 flex-grow flex flex-col">
              <h2 className="font-bold text-sm">{`Energy Level ${nextEnergyLevel.level}`}</h2>
              <p className="text-xs">{`${nextEnergyLevel.energy_bonus}+ Limit Energy`}</p>
            </div>
            {isEnergyUpgraded(nextEnergyLevel.id) ? (
              <button className="ml-auto bg-gray-500 px-2 text-white p-1 rounded-md">Upgraded</button>
            ) : (
              <button
                onClick={() => handleUpgradeEnergy(nextEnergyLevel)}
                className="ml-auto bg-green-600 px-2 text-white p-1 rounded-md"
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </button>
            )}
          </div>
        ) : (
          <div className="flex items-center bg-yellow-400 rounded-md py-3 px-4 w-full mt-1">
            <p className="text-sm mx-auto">Max</p>
          </div>
        )}
      </div>
      {/* Button Energy End */}

      {/* Card Slider for Skins Start */}
      <div className="w-full mt-8">
        <h1 className="text-md font-bold font-sans px-4">Buy Skins:</h1>
        <Slider {...settings} className="">
          {skins.map((skin) => (
            <div key={skin.id} className="p-4">
              <div className="bg-teal-700 rounded-md shadow-md p-4 flex flex-col items-center">
                <img
                  src={skin.img_url}
                  alt={skin.id}
                  className="w-24 h-24 mb-2 bg-teal-800 py-1 px-2 rounded-lg"
                />
                <p className="text-sm bg-teal-900 rounded-md px-1 text-yellow-400">{skin.price} Coins</p>
                {isSkinPurchased(skin.id) ? (
                  <button className="mt-2 bg-yellow-500 text-dark pointer outline py-1 px-4 rounded-md">
                    Owned
                  </button>
                ) : (
                  <button
                    // onClick={() => handleBuySkin(skin.id)}
                    onClick={buySkin}
                    className="mt-2 bg-yellow-500 text-dark pointer outline py-1 px-4 rounded-md"
                  >
                    Buy
                  </button>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* Card Slider for Skins End */}
    </div>
  );
};

const App = () => {
  return (
    <WebAppProvider options={{ smoothButtonsTransition: true }}>
      <Store />
    </WebAppProvider>
  );
};

export default App;
