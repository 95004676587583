import React, { useState, useEffect, useCallback, useContext } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GlobalContext from '../context/ContextProvider';
import { shortenWalletAddress } from '../utils/globalUtils';
import profileImg from '../assets/profile.png'
import { getUserData, getUserRewardsByUserId } from '../utils/api';
import config from '../utils/config';

const ModalProfile = ({ isOpen, onRequestClose, setIsProfileModalOpen, telegramId, coinCount, username, telegramName, profilePhotoUrl, onSkinSelect }) => {
  const [isClaiming, setIsClaiming] = useState(false);
  const [LoadSkinData, setLoadSkinData] = useState('start');
  const [UserRewards, setUserRewards] = useState([]);
  const [userSkins, setUserSkins] = useState([]);
  const navigate = useNavigate();
  const {
    state,
    setUserLevelColor,
    setUserActiveSkin,
    setDataUser,
    setMaxEnergy,
    setCoinCount,
    setCurrentLevel
  } = useContext(GlobalContext);

  const fetchUserSkins = useCallback(async () => {
    try {
      setLoadSkinData('Load');
      const { data: userSkinsData, error } = await supabase
        .from('user_skins')
        .select('skin_id')
        .eq('user_id', telegramId);

      if (error) {
        throw error;
      }

      setUserSkins(userSkinsData);
      fetchSkinImages(userSkinsData);
      setLoadSkinData('Ready');
    } catch (error) {
      setLoadSkinData('Ready');
      console.error('Error fetching user skins:', error.message);
    }
  }, [telegramId]);

  const fetchUserRewards = async () => {
    try {
      const resp = await getUserRewardsByUserId(telegramId, state?.DataUser?.level_id);
      setUserRewards(resp);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (telegramId) {
      fetchUserSkins();
      fetchUserRewards();
    }
  }, [telegramId, fetchUserSkins]);

  const fetchSkinImages = useCallback(async (userSkins) => {
    try {
      const skinIds = userSkins.map(skin => skin.skin_id);

      const { data: skinImagesData, error } = await supabase
        .from('skins')
        .select('id, img_url')
        .in('id', skinIds);

      if (error) {
        throw error;
      }

      const userSkinsWithImages = userSkins.map(userSkin => ({
        ...userSkin,
        img_url: skinImagesData.find(skin => skin.id === userSkin.skin_id)?.img_url
      }));

      setUserSkins(userSkinsWithImages);
    } catch (error) {
      console.error('Error fetching skin images:', error.message);
    }
  }, [userSkins]);

  const claimReward = async (user_rewards) => {
    try {
      setIsClaiming(true);

      const { data: user, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('telegram_id', telegramId)
        .single();

      if (userError) throw userError;

      const newLevelId = Math.max(...user_rewards.map(reward => reward.level_id));
      const newMaxEnergy = Math.max(...user_rewards.map(reward => reward.levels.energy_limit));
      const totalRewardCoins = user_rewards.reduce((total, reward) => total + reward.levels.reward_coins, 0);
      const newCoins = user.coins + totalRewardCoins;

      const { data: updatedUser, error: updateError } = await supabase
        .from('users')
        .update({
          level_id: newLevelId,
          max_energy: newMaxEnergy,
          coins: newCoins,
          update_at: new Date().toISOString()
        })
        .eq('telegram_id', telegramId);

      if (updateError) throw updateError;

      const claimedRewards = user_rewards.map(reward => {
        const { levels, ...rewardData } = reward;
        return {
          ...rewardData,
          is_claimed: true,
          claimed_at: new Date().toISOString()
        };
      });

      const { error: rewardError } = await supabase
        .from('user_rewards')
        .upsert(claimedRewards);

      if (rewardError) throw rewardError;

      await fetchUserRewards();
      const response = await getUserData(telegramId);
      setDataUser(response?.data);
      setCoinCount(response?.data.coins);
      setMaxEnergy(response?.data.max_energy);
      setCurrentLevel(response?.data.levels);
      setUserLevelColor(response?.data.levels.hex_color);

      console.log('Reward claimed successfully');
    } catch (error) {
      console.error('Error claiming reward:', error.message);
    } finally {
      setIsClaiming(false);
    }
  };

  const selectSkin = async (skinId) => {
    try {
      const { data: skinData, error: skinError } = await supabase
        .from('skins')
        .select('img_url')
        .eq('id', skinId)
        .single();

      if (skinError) {
        throw skinError;
      }

      await supabase
        .from('users')
        .update({ skin_id: skinId })
        .eq('telegram_id', telegramId);

      console.log(`Skin ${skinId} selected!`);
      setUserActiveSkin(skinData.img_url);
      setIsProfileModalOpen(false);
    } catch (error) {
      console.error('Error selecting skin:', error.message);
    }
  };
  
  const redirectToStore = () => {
    const storeUrl = `${config.STORE_URL}/?telegram_id=${telegramId}`;
    window.open(storeUrl, '_blank');
  };

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <button className="slick-prev">Previous</button>,
    nextArrow: <button className="slick-next">Next</button>
  };

  const canClaimReward = UserRewards.length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Profile Modal"
      className="modal font-mono"
      overlayClassName="modal-overlay"
    >
      <div className="relative">
        <div className="flex items-center">
          <img
            src={profilePhotoUrl || profileImg}
            alt="User Avatar"
            className="w-12 h-12 rounded-full mr-4"
            style={{ border: `4px solid ${state.UserLevelColor}` }}
          />
          <div>
            <h2 className="font-bold text-yellow-400 text-md text-nowrap">{telegramName}</h2>
            <p className="text-white text-sm">@{username}</p>
          </div>
        </div>
        <button
          className={`my-3 py-2 w-full text-md rounded-md ${canClaimReward
            ? 'bg-green-700 text-white'
            : 'bg-gray-900 text-white'
            }`}
          onClick={() => { claimReward(UserRewards) }}
          disabled={isClaiming || !canClaimReward}
        >
          {isClaiming
            ? 'Claiming Reward...'
            : canClaimReward
              ? `🎁 Level Up Reward`
              : 'No rewards available'}
        </button>
        <div className="mt-1">
          <p className="text-sm font-bold text-white mb-2">Coins: {coinCount}</p>
          <p className="text-sm font-bold text-white mb-2">Level: {state?.CurrentLevel.name_level || '-'}</p>
          <p className="text-sm font-bold text-white mb-2">Wallet: {shortenWalletAddress(state?.DataUser?.wallet_address)}</p>
        </div>
        <div className="mt-3">
          <p className="text-sm font-bold text-white mb-2">Your Skins:</p>
          {LoadSkinData === 'Load' ? <span className="text-white text-sm">Loading...</span>
            :
            <>
              {LoadSkinData === 'Ready' && userSkins.length === 0 ? (
                <p className="text-white text-xs py-2 text-center bg-slate-900 my-5 rounded-md">You don't have any skins yet.</p>
              ) : LoadSkinData === 'Ready' && userSkins.length === 1 ? (
                <div key={0} className="p-2">
                  <img
                    src={userSkins[0].img_url}
                    alt={`Skin ${userSkins[0].skin_id}`}
                    className="w-16 h-16 bg-slate-900 rounded-md py-1 ml-2 object-contain cursor-pointer"
                    onClick={() => selectSkin(userSkins[0].skin_id)}
                  />
                </div>
              ) : (
                <>
                  {LoadSkinData === 'Ready' && userSkins.length > 1 ?
                    <Slider {...sliderSettings}>
                      {userSkins.map((skin, index) => (
                        <div key={index} className="p-2">
                          <img
                            src={skin.img_url}
                            alt={`Skin ${skin.skin_id}`}
                            className="w-full h-auto bg-slate-900 rounded-md py-1 ml-1 object-contain cursor-pointer"
                            onClick={() => selectSkin(skin.skin_id)}
                          />
                        </div>
                      ))}
                    </Slider>
                    :
                    <p className='text-white text-sm'>
                      Loading...
                    </p>
                  }
                </>
              )}
            </>
          }
        </div>
        <button onClick={redirectToStore} className="max-w-fit mt-1 py-1 px-4 bg-yellow-500 text-white font-bold border-4 border-yellow-700 rounded-none rounded flex flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm">
          Buy Skins
        </button>
      </div>
    </Modal>
  );
};

export default ModalProfile;