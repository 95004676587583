import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Leaderboard from './pages/leaderboard';
import Earn from './pages/earn';
import Store from './pages/store';
import Squad from './pages/squad';
import SquadRoom from './pages/squad-room';
import Friends from './pages/friends';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './context/ContextProvider';
import { ThirdwebProvider } from "thirdweb/react";

// import { createWeb3Modal } from '@web3modal/wagmi/react'
// import { http, createConfig, WagmiProvider } from 'wagmi'
// import { reconnect } from '@wagmi/core'
// import { walletConnect } from 'wagmi/connectors'
// import { mainnet, flare } from 'wagmi/chains'
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { authConnector } from '@web3modal/wagmi';

// const queryClient = new QueryClient()

// const projectId = process.env.REACT_APP_WC_ID

// const metadata = {
//   name: 'SPUN FARMER',
//   description: 'SPUN FARMER TELEGRAM APP',
//   url: 'http://localhost:3008/',
//   icons: ['https://avatars.githubusercontent.com/u/37784886']
// }

// const connectors = [];
// connectors.push(walletConnect({ projectId, metadata, showQrModal: false }));
// connectors.push(authConnector({
//   options: { projectId },
//   email: false,
//   showWallets: true,
//   walletFeatures: true
// }));

// const config = createConfig({
//   chains: [mainnet, flare],
//   transports: {
//     [mainnet.id]: http(),
//     [flare.id]: http(),
//   },
//   connectors
// })
// reconnect(config);

// createWeb3Modal({
//   featuredWalletIds: [
//     '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
//     '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
//     '37a686ab6223cd42e2886ed6e5477fce100a4fb565dcd57ed4f81f7c12e93053'
//   ],
//   wagmiConfig: config,
//   projectId,
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}> */}
    <ThirdwebProvider>
      <ContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/pages/leaderboard" element={<Leaderboard />} />
            <Route path="/pages/earn" element={<Earn />} />
            <Route path="/pages/store" element={<Store />} />
            <Route path="/pages/squad" element={<Squad />} />
            <Route path="/pages/squad-room" element={<SquadRoom />} />
            <Route path="/pages/friends" element={<Friends />} />
          </Routes>
        </Router>
      </ContextProvider>
    </ThirdwebProvider>
    {/* </QueryClientProvider>
    </WagmiProvider> */}
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
