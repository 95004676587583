import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDroplet } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ModalProfile from '../component/profilemodal';
import squadImg from "../assets/squad.png";
import spunImg from "../assets/spun.png";
import moneyImg from "../assets/money.png";
import cartImg from "../assets/cart.png";
import friendImg from "../assets/friend.png";
import leaderboardImg from "../assets/leaderboard.png";
import supabase from '../supabaseClient';
import moment from 'moment';
import GlobalContext from '../context/ContextProvider';
import Loading from '../component/Loading';
import { createUserRewards, getReferralByUserId, getReferralRewards, getUserData, getUserReferralRewardsById, setReferralByUserId, uploadImageFromUrl } from '../utils/api'
import RewardModal from '../component/RewardModal';
import ConfettiExplosion from 'react-confetti-explosion';
import Notification from '../component/Notification';
import config from '../utils/config';

const tele = window.Telegram.WebApp;

function Home() {
  const navigate = useNavigate();
  const [isExploding, setIsExploding] = useState(false);
  const [isCoinClicked, setIsCoinClicked] = useState(false);
  const [telegramId, setTelegramId] = useState(null);
  const [username, setUsername] = useState('');
  const [telegramName, setTelegramName] = useState('');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const [referrer, setReferrer] = useState('');
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [IsRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const [currentEnergy, setCurrentEnergy] = useState(0);
  const [lastEnergyReset, setLastEnergyReset] = useState(null);
  // const [showLevelUpNotification, setShowLevelUpNotification] = useState(false);
  // const [borderColor, setBorderColor] = useState('#fffff');
  const [selectedSkinId, setSelectedSkinId] = useState(null); // State to store selected skin id
  // const [selectedSkinUrl, setSelectedSkinUrl] = useState(null); // State to store selected skin URL
  const [squadId, setSquadId] = useState(null);
  const {
    state,
    setIsDataReady,
    setDataUser,
    setUserLevelColor,
    setUserActiveSkin,
    setProfilePhoto,
    setMaxEnergy,
    setCoinCount,
    setCurrentLevel,
    setIsNotifOpen
  } = useContext(GlobalContext);

  // Handle navigation for Squad button
  const handleSquadNavigation = () => {
    if (squadId) {
      navigate(`/pages/squad-room?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}&squad_id=${squadId}`); // Jika pengguna memiliki squadId, arahkan ke squad-room
    } else {
      navigate(`/pages/squad?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}&profile_photo_url=${encodeURIComponent(profilePhotoUrl)}`); // Jika belum memiliki squadId, arahkan ke halaman untuk memilih squad
    }
  };

  // Fetch skin URL based on selected skin id
  useEffect(() => {
    if (selectedSkinId) {
      const fetchSkinUrl = async () => {
        try {
          // Fetch skin data from Supabase
          const { data: skinData, error } = await supabase
            .from('skins')
            .select('img_url')
            .eq('id', selectedSkinId)
            .single();

          if (error) {
            throw error;
          }

          // Set selected skin URL
          setUserActiveSkin(skinData.img_url);
          // setSelectedSkinUrl(skinData.img_url);
        } catch (error) {
          console.error('Error fetching skin URL:', error.message);
        }
      };

      // Call fetchSkinUrl function
      fetchSkinUrl();
    }
  }, [selectedSkinId]);

  const handleSkinSelect = useCallback(async (skinId) => {
    try {
      await supabase
        .from('users')
        .update({ skin_id: skinId })
        .eq('telegram_id', telegramId);

      setSelectedSkinId(skinId);
    } catch (error) {
      console.error('Error selecting skin:', error.message);
    }
  }, [telegramId]);

  const generateUniqueReferralCode = useCallback(async () => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let isUnique = false;
    let result = '';

    while (!isUnique) {
      result = '';
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      // checking is referral code exists
      const { data, error } = await supabase
        .from('users')
        .select('referral_code')
        .eq('referral_code', result);

      if (error) {
        console.error('Error checking referral code:', error);
        // Jika terjadi error, kita asumsikan kode unik untuk menghindari loop tak terbatas
        isUnique = true;
      } else {
        // Jika tidak ada data yang dikembalikan, berarti kode unik
        isUnique = data.length === 0;
      }
    }

    return result;
  }, []);

  const checkReferredReward = async (telegram_id, referrerId) => {
    try {
      if (!referrerId || referrerId === 'null' || telegram_id === referrerId || !telegram_id) {
        return;
      }

      const { data: users } = await getUserData(referrerId);
      if (!users) {
        setIsNotifOpen(true);
        return;
      }

      const { statusCode } = await setReferralByUserId(telegram_id, referrerId);
      if (statusCode === 200) {
        setIsRewardModalOpen(true);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const checkReferrerReward = async (telegram_id) => {
    try {
      if (!telegram_id) {
        console.log('Telegram id not found!');
        return;
      }

      const { data: referralData } = await getReferralByUserId(telegram_id);
      const { data: referralRewards } = await getReferralRewards();

      if (!referralData || !referralRewards) {
        console.log('No referral data or referral rewards found!');
        return;
      }

      const inviteCount = referralData.length;

      const potentialRewards = referralRewards.filter(reward => inviteCount >= reward.invite_count);

      if (potentialRewards.length === 0) {
        console.log('No rewards to be added.');
        return;
      }

      const { data: userRewards } = await getUserReferralRewardsById(telegram_id);

      const rewardsToAdd = referralRewards.filter(reward => {
        const hasClaimed = userRewards.some(userReward => userReward.reward_id === reward.id);
        return inviteCount >= reward.invite_count && !hasClaimed;
      });

      for (const reward of rewardsToAdd) {
        const { error } = await supabase
          .from('user_referral_rewards')
          .insert([{ user_id: telegram_id, reward_id: reward.id, is_claimed: false, created_at: new Date().toISOString() }]);

        if (error) {
          console.error(`Error adding reward ${reward.id}:`, error.message);
        } else {
          console.log(`Reward ${reward.id} added successfully!`);
        }
      }
    } catch (error) {
      console.log('Error checking referrer rewards:', error);
    }
  };

  const createUser = useCallback(async (telegramId, username, telegramName, profilePhotoUrl, referrerId) => {
    console.log("Starting user creation process");
    try {
      // Fetch the initial level data
      let { data: levels, error: errorLevels } = await supabase
        .from('levels')
        .select('*')
        .eq('level_id', 1)
        .single();

      if (errorLevels) {
        throw new Error('Error fetching level data: ' + errorLevels.message);
      }

      // Generate unique referral code
      const referralCode = await generateUniqueReferralCode();
      console.log("Generated referral code:", referralCode);

      let newImageUrl = null;
      if (profilePhotoUrl) {
        newImageUrl = await uploadImageFromUrl(profilePhotoUrl, telegramId);
      } else {
        newImageUrl = null;
      }

      const newUser = {
        telegram_id: telegramId,
        username: username,
        telegram_name: telegramName,
        coins: 0,
        profile_photo_url: newImageUrl,
        max_energy: levels?.energy_limit,
        level_id: 1,
        current_energy: levels?.energy_limit,
        created_at: new Date().toISOString(),
        last_energy_reset: new Date().toISOString(),
        referral_code: referralCode
      };

      // Insert new user data into the users table
      const { data: users, error } = await supabase
        .from('users')
        .insert([newUser])
        .select('*, levels(*)');

      if (error) {
        throw new Error('Error creating user: ' + error.message);
      }

      console.log("User created successfully", users[0]);
      setDataUser(users[0]);

      setCoinCount(users[0].coins);
      setMaxEnergy(users[0].max_energy);
      setCurrentEnergy(users[0].current_energy);
      setLastEnergyReset(new Date(users[0].last_energy_reset));
      setSelectedSkinId(users[0].skin_id);
      setSquadId(users[0].squad_id);
      setProfilePhoto(users[0].profile_photo_url);
      setCurrentLevel(users[0].levels);
      setUserLevelColor(users[0].levels.hex_color);

      await createUserRewards(telegramId);
      await checkReferredReward(telegramId, referrerId);
    } catch (error) {
      console.error('Error creating user:', error.message);
    }
  }, [generateUniqueReferralCode]);


  const fetchOrCreateUser = useCallback(async (telegramId, username, telegramName, profilePhotoUrl, referrerId) => {
    try {
      const { data: users } = await getUserData(telegramId);

      if (users) {
        console.log(users);
        setDataUser(users);
        setCoinCount(users.coins);
        setMaxEnergy(users.max_energy);
        setCurrentEnergy(users.current_energy);
        setLastEnergyReset(new Date(users.last_energy_reset));
        setSelectedSkinId(users.skin_id);
        setSquadId(users.squad_id);
        setProfilePhoto(users.profile_photo_url);
        setCurrentLevel(users.levels);
        setUserLevelColor(users.levels.hex_color);

        await checkReferrerReward(telegramId);
      } else {
        await createUser(telegramId, username, telegramName, profilePhotoUrl, referrerId);
      }
      setIsDataReady(true);
    } catch (error) {
      console.error('Error fetching or creating user:', error.message);
    }
  }, [createUser]);

  const checkAndResetEnergy = useCallback(async () => {
    if (lastEnergyReset) {
      const now = moment();
      const lastResetMoment = moment(lastEnergyReset);
      const hoursSinceLastReset = now.diff(lastResetMoment, 'hours');

      if (hoursSinceLastReset >= 24) {
        const updatedMaxEnergy = state?.MaxEnergy;
        const newCurrentEnergy = updatedMaxEnergy;

        const { error } = await supabase
          .from('users')
          .update({
            current_energy: newCurrentEnergy,
            last_energy_reset: now.toISOString()
          })
          .eq('telegram_id', telegramId);

        if (error) {
          console.error('Error resetting energy:', error.message);
        } else {
          setCurrentEnergy(newCurrentEnergy);
          setLastEnergyReset(now.toDate());
        }
      }
    }
  }, [lastEnergyReset, state?.MaxEnergy, telegramId]);


  useEffect(() => {
    tele.ready();
    const urlParams = new URLSearchParams(window.location.search);
    const telegramIdFromUrl = urlParams.get('telegram_id');
    const usernameFromUrl = urlParams.get('username');
    const telegramNameFromUrl = urlParams.get('telegram_name');
    const profilePhotoUrlFromUrl = urlParams.get('profile_photo_url');
    const referrerId = urlParams.get('referrer');
    let PPImage = null;

    setTelegramId(telegramIdFromUrl);
    setUsername(usernameFromUrl);
    setTelegramName(telegramNameFromUrl);
    setReferrer(referrerId);
    if (profilePhotoUrlFromUrl && profilePhotoUrlFromUrl.includes("api.telegram.org")) {
      setProfilePhotoUrl(profilePhotoUrlFromUrl);
      PPImage = profilePhotoUrlFromUrl;
    } else {
      setProfilePhotoUrl(null);
      PPImage = null;
    }

    if (telegramIdFromUrl) {
      fetchOrCreateUser(telegramIdFromUrl, usernameFromUrl, telegramNameFromUrl, PPImage, referrerId);
    }
  }, [fetchOrCreateUser]);

  useEffect(() => {
    checkAndResetEnergy();
  }, [checkAndResetEnergy]);

  // useEffect(() => {
  //   // Refresh halaman jika notifikasi muncul
  //   if (showLevelUpNotification) {
  //     setTimeout(() => {
  //       setShowLevelUpNotification(false);
  //       window.location.reload(); // Refresh halaman
  //     }, 5000); // Tentukan durasi notifikasi sebelum direfresh
  //   }
  // }, [showLevelUpNotification]);
  const [updateTimeout, setUpdateTimeout] = useState(null);

  const handleCoinClick = async () => {
    try {
      if (currentEnergy > 0) {
        const updatedCoinCount = state?.CoinCount + 1;
        setCoinCount(updatedCoinCount);
        setIsCoinClicked(true);
        const newCurrentEnergy = currentEnergy - 1;
        setCurrentEnergy(newCurrentEnergy);

        if (updateTimeout) {
          clearTimeout(updateTimeout);
        }

        const newTimeout = setTimeout(async () => {
          await updateCoinCountInDatabase(updatedCoinCount, newCurrentEnergy);
        }, 1000);

        setUpdateTimeout(newTimeout);
      } else {
        console.log('No more energy to click the coin');
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setTimeout(() => {
        setIsCoinClicked(false);
      }, 300);
    }
  };

  const updateCoinCountInDatabase = async (updatedCoinCount, newCurrentEnergy) => {
    try {
      await supabase
        .from('users')
        .update({ current_energy: newCurrentEnergy })
        .eq('telegram_id', telegramId);

      const { data: existingUser, error: existingUserError } = await supabase
        .from('users')
        .select('*')
        .eq('telegram_id', telegramId);

      if (existingUserError) {
        throw existingUserError;
      }

      if (existingUser.length > 0) {
        await supabase
          .from('users')
          .update({ coins: updatedCoinCount })
          .eq('telegram_id', telegramId);

        // console.log('Total coin is updated:', updatedCoinCount);
      }
    } catch (error) {
      console.error('Error updating coin count in database:', error.message);
    }
  };

  const redirectToStore = () => {
    const storeUrl = `${config.STORE_URL}/?telegram_id=${telegramId}`;
    window.open(storeUrl, '_blank');
  };

  if (!state.IsLoading) {
    return (
      <Loading />
    )
  }

  return (
    <>
      {isExploding &&
        <ConfettiExplosion />
      }
      <div className="relative bg-bgtetris bg-cover bg-center min-h-screen flex flex-col items-center justify-between font-mono">
        <Notification
          warningType={'alert'}
          message={'Referral ID is invalid!'}
          IsOpen={state?.IsNotifOpen}
          setIsOpen={setIsNotifOpen}
        />
        <div className="flex flex-col items-center justify-center mt-2 px-4 w-full">
          {/* Container Wallet */}
          <div className="flex justify-between items-center w-full pt-2 space-x-2">
            {/* Container Profile */}
            <div
              className="cursor-pointer bg-teal-800 rounded-md p-4 flex items-center w-3/5"
              onClick={() => setIsProfileModalOpen(true)}>
              <img src={state?.ProfilePhoto || './profile.png'} alt="User Avatar" className="w-10 h-10 rounded-full mx-2" style={{ border: `4px solid ${state.UserLevelColor}` }} />
              <div>
                <h2 className="font-bold text-yellow-400 text-sm truncate">{username}</h2>
                <p className=" mt-1 text-white text-xs">🪙 {state?.CoinCount}</p>
              </div>
            </div>
            {/* Container Squad */}
            <div className="bg-blue-700 text-center w-1/5 rounded-md p-2 py-2 px-2 flex text-white font-bold border-4 border-blue-400 flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm md:flex-none" onClick={handleSquadNavigation}>
              <img src={squadImg} className='h-8 w-5 py-1' alt='squad'></img>
              Squad
            </div>
            {/* Container Earn */}
            <div className="bg-teal-700 text-center w-1/5 rounded-md p-2 py-2 px-2 flex text-white font-bold border-4 border-teal-500 flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm md:flex-none" onClick={() => navigate(`/pages/earn?telegram_id=${telegramId}&username=${username}&telegram_name=${encodeURIComponent(telegramName)}`)}>
              <img src={moneyImg} className='h-8 w-8' alt='money'></img>
              Earn
            </div>
          </div>
        </div>
        {/* Container Game Coins */}
        <div className=" flex flex-col items-center justify-center mt-3 px-4 w-full">
          <div onClick={handleCoinClick} className="cursor-pointer">
            <img src={state?.UserActiveSkin || spunImg} alt="Coin" className={`w-10 h-10 mx-2 my-2 ${isCoinClicked ? 'animate-coin-click' : ''}`} style={{ width: '250px', height: '250px' }} />
          </div>
        </div>
        {/* Container Progress Bar and Buttons */}
        <div className="flex flex-col justify-center items-center w-full px-4">
          {/* Progress Bar */}
          <div className="bg-teal-800 rounded-md flex items-center w-full md:w-1/2 mb-3 md:mb-0">
            <FontAwesomeIcon icon={faDroplet} className="w-4 h-4 text-blue-400 rounded-full my-2 mx-3" />
            <div className="flex-grow">
              <div className="relative h-2 bg-gray-300 my-2">
                <div className="absolute top-0 left-0 h-full bg-yellow-500" style={{ width: `${(currentEnergy / state?.MaxEnergy) * 100}%` }}></div>
              </div>
            </div>
            <div className="flex flex-col items-end ml-4">
              <h1 className='text-white text-xs mr-4'>{currentEnergy}/{state?.MaxEnergy}</h1>
            </div>
          </div>
          {/* Buttons */}
          <div className="flex justify-center mb-4 items-center w-full">
            <button className='py-2 w-full mr-2 bg-green-500 text-white font-bold border-4 border-green-700 rounded-none flex flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm' onClick={() => navigate('/pages/leaderboard')}>
              <div className="flex items-center justify-center mb-1">
                <img src={leaderboardImg} className='w-9 h-9' alt="Leaderboard"></img>
              </div>
              <div className=''>Leaderboard</div>
            </button>
            <button className='py-2 w-full mr-2 bg-blue-500 text-white font-bold border-4 border-blue-700 rounded-none flex flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm' onClick={() => navigate(`/pages/friends?telegram_id=${telegramId}&referrer=${referrer}`)}>
              <div className="flex items-center justify-center mb-1">
                <img src={friendImg} className='w-9 h-9' alt="Friend"></img>
              </div>
              <div className=''>Friends</div>
            </button>
            <button className='py-2 w-full bg-yellow-500 text-white font-bold border-4 border-yellow-700 rounded-none flex flex-col items-center justify-center transform hover:scale-105 active:scale-95 text-sm' onClick={redirectToStore}>
              <div className="flex items-center justify-center mb-1">
                <img src={cartImg} className='w-9 h-9' alt="Store"></img>
              </div>
              <div className=''>Store</div>
            </button>
          </div>
        </div>
        {/* Profile Modal */}
        {isProfileModalOpen &&
          <ModalProfile
            isOpen={isProfileModalOpen}
            onRequestClose={() => setIsProfileModalOpen(false)}
            setIsProfileModalOpen={setIsProfileModalOpen}
            telegramId={telegramId}
            coinCount={state?.CoinCount}
            username={username}
            telegramName={telegramName}
            profilePhotoUrl={state?.ProfilePhoto}
            onSkinSelect={handleSkinSelect}
          />
        }
        {IsRewardModalOpen &&
          <RewardModal
            isOpen={IsRewardModalOpen}
            setIsOpen={setIsRewardModalOpen}
            setIsExploding={setIsExploding}
          />
        }
        {/* Notifikasi Level Up */}
        {/* {showLevelUpNotification && (
        <div className="bg-green-500 text-white rounded-md p-2 mb-4">
          Level Up!
        </div>
      )} */}
      </div>
    </>
  );
}

export default Home;
