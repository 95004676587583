const Loading = () => {
    return (

        <div className="bg-bgtetris h-screen w-screen bg-cover bg-center flex items-center justify-center">

            <div className="w-3/5">
                <p className="leading-snug text-center text-teal-800 font-semibold">Welcome to</p>
                <h1 className="text-center leading-none mb-4 text-teal-800 text-3xl font-bold">Spun Farmer</h1>
                <div className="bg-teal-800 rounded-md p-4 flex items-center justify-center">
                    <h1 className="text-yellow-400 text-base font-bold">Loading...</h1>
                </div>
            </div>

        </div >
    );
};

export default Loading;